<template>
<v-container id="complex-search-bar">
    <v-row wrap no-gutters>

        <v-col cols="2" class="py-2" v-if="$route.params.dsp !== $YOUTUBE">
            <p> Retry </p>
            <v-btn-toggle v-model="retry" mandatory small dense>
                <v-btn text>
                    false
                </v-btn>
                <v-btn text>
                    N/A
                </v-btn>
                <v-btn text>
                    true
                </v-btn>
            </v-btn-toggle>
        </v-col>

        <v-col cols="2" class="py-2">
            <p> Fast retry </p>
            <v-btn-toggle v-model="fast_retry" mandatory small dense>
                <v-btn text>
                    false
                </v-btn>
                <v-btn text>
                    N/A
                </v-btn>
                <v-btn text>
                    true
                </v-btn>
            </v-btn-toggle>
        </v-col>

        <v-col cols="2" class="py-2">
            <p> Active </p>
            <v-btn-toggle v-model="is_active" mandatory small dense>
                <v-btn text>
                    false
                </v-btn>
                <v-btn text>
                    N/A
                </v-btn>
                <v-btn text>
                    true
                </v-btn>
            </v-btn-toggle>
        </v-col>

        <v-col cols="2" class="py-2">
            <p> Opti auto </p>
            <v-btn-toggle v-model="opti_auto" mandatory small dense>
                <v-btn text>
                    false
                </v-btn>
                <v-btn text>
                    N/A
                </v-btn>
                <v-btn text>
                    true
                </v-btn>
            </v-btn-toggle>
        </v-col>

        <v-col cols="2" class="py-2 align-content-center">
            <v-row wrap class="justify-center">
                <v-btn color="info" v-on:click="askForSearchComplex()">Search</v-btn>
            </v-row>
        </v-col>
    </v-row>
    <v-divider class="ma-3"></v-divider>
    <v-row no-gutters>
      <v-col cols="12" class="py-2">
        <p> Baseline Type </p>
        <v-btn-toggle :value="baselineSearch" @change="emitBaselineSearch" mandatory small dense>
          <v-btn text value="ab_test">
            ab_test
          </v-btn>
          <v-btn text value="before_after">
            before_after
          </v-btn>
          <v-btn text value="N/A">
            N/A
          </v-btn>
          <v-btn text value="other">
            other
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
</v-container>
</template>

<script>
export default {
  name: 'ComplexSearchBar',
  props: {
    baselineSearch: {
      type: String
    }
  },
  components: {

  },
  data () {
    return {
      retry: 1,
      fast_retry: 1,
      is_active: 1,
      opti_auto: 1
    }
  },
  methods: {
    askForSearchComplex: function () {
      let searchObject = {
        retry: this.retry,
        fast_retry: this.fast_retry,
        is_active: this.is_active,
        opti_auto: this.opti_auto
      }
      this.$emit('search-complex', searchObject)
    },
    resetValues: function () {
      this.retry = 1
      this.fast_retry = 1
      this.is_active = 1
      this.opti_auto = 1
    },
    checkValues: function () {
      let currentSearch = this.$store.getters.getCurrentSearch
      let valueToCheck = this.$store.getters.getCurrentSearchKeys

      for (let key in valueToCheck) {
        let value = valueToCheck[key]
        let queryKey = 'q_' + value

        if (typeof this.$route.query[queryKey] !== 'undefined') {
          this.$data[value] = this.$route.query[queryKey] === 'false' ? 0 : 2
        } else if (typeof currentSearch[value] !== 'undefined' && currentSearch[value] !== null && currentSearch[value] !== '') {
          this.$data[value] = currentSearch[value] === 'false' ? 0 : 2
        }
      }
    },
    emitBaselineSearch (value) {
      this.$emit('update:baselineSearch', value)
    }
  },
  created: function () {
    this.resetValues()
    this.checkValues()
  },
  mounted: function () {

  },
  computed: {

  },
  watch: {
    'retry': function (retry) {

    },
    '$route.params.dsp': function (dsp) {
      this.resetValues()
    }
  }
}
</script>

<style>

div#complex-search-bar {
  max-width: none;
  background-color: #FAFAFA;
}
div#complex-search-bar .v-btn-toggle {
  border-bottom-style: inset;
}
div#complex-search-bar .v-btn-toggle button {
  background-color: white;

}

</style>
