import { render, staticRenderFns } from "./ComplexSearchBar.vue?vue&type=template&id=2c187f28"
import script from "./ComplexSearchBar.vue?vue&type=script&lang=js"
export * from "./ComplexSearchBar.vue?vue&type=script&lang=js"
import style0 from "./ComplexSearchBar.vue?vue&type=style&index=0&id=2c187f28&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VBtnToggle,VCol,VContainer,VDivider,VRow})
